import React from 'react';
import { useParams } from 'react-router-dom';
import Title from '../Title.jsx';
import products from '../../data/products.js';

const ProductDescription = () => {
  const { slug } = useParams();
  // const navigate = useNavigate();
  const product = products.find((product) => product.slug === slug);

  if (!product) {
    return <div>Product not found</div>;
  }
  // const goBack = () => {
  //   navigate(-1);
  // };
  return (
    <div className='flex flex-col items-center p-6 md:p-12 gap-6 md:gap-12'>
      <div className='flex flex-col gap-4'>
        {/* <button onClick={goBack} className='text-lg text-primary-blue'>
          &larr; Back to Illustrations
        </button> */}
        <Title>{product.title}</Title>
      </div>
      <div className='flex flex-col lg:flex-row gap-6 lg:gap-12 max-w-6xl'>
        <div className='text-center text-lg lg:text-xl font-light leading-8 text-primary-blue'>
          <div dangerouslySetInnerHTML={{ __html: product.description }} />
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;