import React from 'react';
import Title from '../Title';

import AboutCompanyPic from '../../assets/about/skyline.jpg';

import aboutCompany from '../../data/aboutCompany';

import '../../index.css';

const AboutCompany = () => {
  const euroclearTitle = 'About Euroclear';
  const cardsData = [
    '€37 Trillion clients’ assets under custody',
    'Euroclear Bank is rated AA+ by Fitch and AA by Standard & Poor’s',
  ];

  return (
    <div className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>A Euroclear Company</Title>
      <div className='flex flex-col lg:flex-row mx-auto gap-12 max-w-[1600px] w-full'>
        <div className='flex flex-col items-start justify-start w-full gap-12 lg:w-1/2 lg:order-2'>
          <div className='flex flex-col items-center justify-center gap-12 h-auto w-full'>
            <img
              src={AboutCompanyPic}
              alt='Quantessence'
              loading='lazy'
              className='max-w-full max-h-full rounded object-contain'
            />
            <div className='hidden lg:flex flex-col gap-6'>
              <h2 className='text-2xl font-medium text-primary-blue text-center'>{euroclearTitle}</h2>
              <div className='flex flex-col lg:flex-row gap-6 w-full'>
                {cardsData.map((cardTitle) => (
                  <div className='w-full h-full flex flex-col justify-between bg-white rounded p-4 shadow-lg transform transition-transform hover:scale-105'>
                    <p className='min-h-16 text-center text-lg lg:text-xl font-medium text-primary-red'>
                      {cardTitle}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full lg:w-1/2 lg:order-1 gap-6'>
          <div className='flex flex-col gap-6 text-lg lg:text-xl text-justify font-light text-primary-blue'>
            {aboutCompany.content}
          </div>
          <div className='flex flex-col lg:hidden gap-12'>
          <h2 className='text-lg font-medium text-primary-blue text-center'>{euroclearTitle}</h2>
            <div className='flex flex-col gap-12'>
              {cardsData.map((cardTitle) => (
                <div className='w-full h-full flex flex-col justify-between bg-white rounded p-4 shadow-lg transform transition-transform hover:scale-105'>
                  <p className='min-h-16 text-center text-lg lg:text-xl font-medium text-primary-red'>
                    {cardTitle}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
