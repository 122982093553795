import React, { useState, useEffect } from 'react';
import Video from '../../assets/splash/Splash.mp4';
import MobileGif from '../../assets/splash/Safarigif.gif';
import { useTranslation } from 'react-i18next';

const Splash = () => {
  const { t } = useTranslation();
  const [isSafari, setIsSafari] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const safariCheck = /^((?!chrome|android).)*safari/.test(userAgent);
    const mobileCheck = /android|iphone|ipad|ipod/.test(userAgent);

    setIsSafari(safariCheck);
    setIsMobile(mobileCheck);
  }, []);

  return (
    <div className='relative flex justify-center items-center w-full h-screen bg-black'>
      {isSafari || isMobile ? (
        <div className='absolute w-full h-full'>
          <img
            src={MobileGif}
            alt='Splash GIF'
            className='w-full h-full object-cover'
            style={{
              WebkitTransform: 'translateZ(0)',
              transform: 'translateZ(0)',
            }}
          />
        </div>
      ) : (
        <div className='absolute w-full h-full'>
          <video
            src={Video}
            autoPlay
            muted
            loop
            className='w-full h-full object-cover'
            style={{
              WebkitTransform: 'translateZ(0)',
              transform: 'translateZ(0)',
            }}
          />
        </div>
      )}
      <div className='relative z-30 text-white text-center'>
        <h1 className='flex flex-col gap-8 text-xl xs:text-2xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl'>
          <span className='inline-block w-full text-center font-medium antialiased'>
            {t('welcome_part_1')}
          </span>
          <span className='inline-block w-full text-center font-medium antialiased'>
            {t('welcome_part_2')}
          </span>
        </h1>
      </div>
      <div className='absolute bottom-0 m-4'>
        <p className='text-center text-xs text-white'>
          Quantessence is authorised and regulated by the Financial Services and
          Markets Authorities (FSMA) in Belgium and by the Financial Conduct
          Authority (FCA) in the UK (firm reference number 975752)
        </p>
      </div>
    </div>
  );
};

export default Splash;