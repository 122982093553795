import DeutscheBank from '../assets/clients/DBank.png';
import Hilbert from '../assets/clients/Hilbert.png';
import SwissRe from '../assets/clients/SwissRE.png';
import Zurich from '../assets/clients/Zurich.png';
import Phoenix from '../assets/clients/Phoenix.svg';
import Generali from '../assets/clients/Generali.png';
import MunichRe from '../assets/clients/MunichRE.png';
import BBVA from '../assets/clients/BBVA.png';
import BlackRock from '../assets/clients/BlackRock.png';
import Fidelity from '../assets/clients/FidelityInternational.png';

const clients = [
  {
    id: 1,
    cover: DeutscheBank,
    title: 'Deutsche Bank',
  },
  {
    id: 2,
    cover: Hilbert,
    title: 'Hilbert',
  },
  {
    id: 3,
    cover: SwissRe,
    title: 'Swiss Re',
  },
  {
    id: 4,
    cover: Zurich,
    title: 'Zurich',
  },
  {
    id: 5,
    cover: Phoenix,
    title: 'Phoenix',
  },
  {
    id: 6,
    cover: Generali,
    title: 'Generali',
  },
  {
    id: 7,
    cover: MunichRe,
    title: 'Munich Re',
  },
  {
    id: 8,
    cover: BBVA,
    title: 'BBVA',
  },
  {
    id: 9,
    cover: BlackRock,
    title: 'BlackRock',
  },
  {
    id: 10,
    cover: Fidelity,
    title: 'Fidelity International',
  }
];

export default clients;