import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import clients from '../../data/clients';

const useMediaQuery = (width) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= width);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= width);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return isMobile;
};

const Carousel = () => {
  const isMobile = useMediaQuery(768);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: !isMobile,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true,
    centerMode: false,
    centerPadding: '0',
    customPaging: (i) => (
      <div className='custom-dot'>
        <span className='dot'></span>
      </div>
      ),
  };

  return (
    <div className='max-w-7xl mx-auto w-full'>
      <Slider {...settings} className='pb-12'>
        {clients.map((client, index) => (
          <div key={index} className='flex justify-center items-center h-40'>
            <div className='w-full flex justify-center items-center'>
              <img
                src={client.cover}
                alt={client.imgAlt}
                loading='lazy'
                className='object-contain'
                style={{
                  width: '200px',
                  height: '150px',
                  maxWidth: '100%',
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;