import React, { useEffect } from 'react';

const Modal = ({ isOpen, closeModal, selectedCard }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
  if (!isOpen || !selectedCard) return null;

  return (
    <div className='fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75'>
      <div className='relative bg-white p-6 md:p-12 rounded w-[80vw] lg:w-[60vw] h-[80vh] md:h-auto max-w-[80vw] max-h-[100vh] min-w-[300px] min-h-[300px] overflow-y-auto'>
        <button
          className='absolute top-4 right-4 text-3xl'
          onClick={closeModal}
        >
          &times;
        </button>
        <div className='flex flex-col items-center text-center gap-6'>
          <img
            src={selectedCard.image}
            alt={selectedCard.name}
            loading='lazy'
            className='w-36 h-36 border rounded-full object-cover object-top'
          />
          <div className='flex flex-col gap-6'>
            <h2 className='text-xl lg:text-2xl font-medium text-primary-blue'>
              {selectedCard.name}
            </h2>
            <p className='text-justify text-base lg:text-lg font-light leading-8 text-primary-blue'>
              {selectedCard.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;