import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LinkedinLogo from '../assets/linkedin.svg';
import QR from '../assets/Q Logo Red.svg';
import EuroclearLogo from '../assets/euroclearLogo.png';

const navItems = [
  { to: '/', label: 'Home' },
  { to: '/services', label: 'Services' },
  { to: '/illustrations', label: 'Supported Products' },
  { to: '/news', label: 'News' },
  { to: '/about', label: 'About us' },
  { to: '/careers', label: 'Careers' },
  { to: '/contact', label: 'Contact us' },
];

const MobileNavItems = ({ onItemClick }) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className='flex items-center px-12'>
        <img src={QR} alt='' className='h-12' loading='lazy' />
      </div>
      <ul className='flex flex-col gap-6 px-12 py-6'>
        {navItems.map(({ to, label }) => (
          <li key={to}>
            <Link
              to={to}
              className={`block text-lg font-medium py-2 border-b-2 ${
                (pathname === '/' && to === '/') ||
                (to !== '/' && pathname.startsWith(to))
                  ? 'text-primary-blue border-primary-red'
                  : 'text-primary-blue'
              }`}
              onClick={() => {
                if (onItemClick) {
                  onItemClick();
                }
              }}
            >
              {label}
            </Link>
          </li>
        ))}
        <div className='flex justify-center gap-6'>
          {/* <a
            href='https://www.youtube.com/@quantessenceltd.3054'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={YoutubeLogo} alt='Youtube' className='h-8 w-8' />
          </a> */}
          <a
            href='https://www.linkedin.com/company/quantessence-financial-ltd/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={LinkedinLogo} alt='Linkedin' className='h-8 w-8' loading='lazy' />
          </a>
          <a
            href='https://www.euroclear.com/en.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={EuroclearLogo} alt='Euroclear' className='w-10' loading='lazy' />
          </a>
        </div>
      </ul>
    </>
  );
};

export default MobileNavItems;