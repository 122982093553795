import React from 'react';
import { useParams } from 'react-router-dom';
import Title from '../Title';
import articles from '../../data/articles';
import '../../index.css';

const ArticleDetail = () => {
  const { slug } = useParams();
  // const navigate = useNavigate();
  const article = articles.find((article) => article.slug === slug);

  // const goBack = () => {
  //   navigate(-1);
  // };

  if (!article) {
    return <div>Article not found</div>;
  }

  const getEmbedUrl = (url) => {
    if (!url) return '';

    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.includes('v=')
        ? url.split('v=')[1]
        : url.split('/').pop();
      return `https://www.youtube.com/embed/${videoId}`;
    }

    if (url.includes('vimeo.com')) {
      const videoId = url.split('/').pop();
      return `https://player.vimeo.com/video/${videoId}`;
    }

    return url;
  };
  
  return (
    <div className='flex flex-col items-center justify-center p-6 lg:p-12 gap-6 lg:gap-12 text-primary-blue max-w-[1600px] mx-auto w-full'>
       <div className='flex flex-col gap-4'>
        {/* <button onClick={goBack} className='text-lg text-primary-blue'>
          &larr; Back to Latest Articles
        </button> */}
        <Title>{article.title}</Title>
      </div>
      
      <p className='text-lg lg:text-xl font-light text-gray-500'>
        {`Published by `}
        <span className='font-medium text-primary-red'>Quantessence</span>
        {` on ${article.date} - London, UK`}
      </p>
      <div className='w-full'>
        {!article.videoUrl && article.imgAlt && (
          <img
            src={article.imgSrc}
            alt={article.imgAlt}
            loading='lazy'
            className='float-left h-48 w-full max-w-full md:max-w-80 mr-8 mb-4 rounded object-cover'
          />
        )}
        <div className='text-justify text-lg lg:text-xl font-light text-primary-blue leading-8'>
          <div dangerouslySetInnerHTML={{ __html: article.descriptionfull }} />
        </div>
      </div>
      {article.videoUrl && (
        <div className='flex w-full max-w-4xl'>
          <iframe
            title={article.title}
            src={getEmbedUrl(article.videoUrl)}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            className='w-full aspect-video rounded shadow-lg'
          />
        </div>
      )}
    </div>
  );
}

export default ArticleDetail;