import React from 'react';
import Title from '../Title';
import Carousel from '../Homepage/Carousel';
import '../../index.css';

const Clients = () => {
  return (
    <section className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Trusted by</Title>
      <Carousel />
    </section>
  );
};

export default Clients;