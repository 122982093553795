import React, { useEffect, useRef } from 'react';
import services from '../../data/services';

const ServiceCard = () => {
  const cardRefs = useRef([]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;

    cardRefs.current.forEach((card) => {
      if (card) {
        const cardTop = card.getBoundingClientRect().top;
        if (cardTop < windowHeight * 0.8) {
          card.classList.add('opacity-100', 'translate-y-0');
          card.classList.remove('opacity-0', 'translate-y-5');
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='flex flex-col items-center gap-6 lg:gap-12'>
      {services.map((service, index) => (
        <div
          key={service.title}
          ref={(el) => (cardRefs.current[index] = el)}
          className={`flex flex-col lg:flex-row gap-6 lg:gap-12 max-w-6xl transform transition-transform transition-opacity duration-500 ease-out opacity-0 translate-y-5 ${
            index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
          }`}
        >
          <div className='w-full lg:w-2/4 h-50 md:h-80'>
            <img
              src={service.image}
              alt={service.title}
              loading='lazy'
              className='w-full h-full object-cover rounded'
            />
          </div>
          <div className='flex flex-col justify-center gap-6 w-full lg:w-2/4 text-justify'>
            <h2 className='text-xl lg:text-2xl font-medium text-primary-red'>
              {service.title}
            </h2>
            <p className='text-lg lg:text-xl font-light leading-8 text-primary-blue'>
              {service.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceCard;