import Splash from '../components/Homepage/Splash';
import About from '../components/Homepage/About';
import Clients from '../components/Homepage/Clients';
// import Testimonials from '../components/Homepage/Testimonials';

function Homepage() {
  return (
    <>
      <Splash />
      <About />
      <Clients />
      {/* <Testimonials /> */}
    </>
  );
}

export default Homepage;